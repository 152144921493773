<template>
  <div>
    <div>
      <b-row>
        <b-col
          lg="4"
          md="6"
          sm12
        >
          <b-card body-class="pb-50">
            <h6 class="mb-3">
              Total Revenue Sum (Pending Disburse)
            </h6>
            <h2 class="font-weight-bolder text-right">
              <vue-numeric
                read-only
                :precision="2"
                currency="MYR"
                separator=","
                :value="settlementOverview.total_revenue_sum"
              />
            </h2>
          </b-card>
        </b-col>
        <b-col
          lg="4"
          md="6"
          sm12
        >
          <b-card body-class="pb-50">
            <h6 class="mb-3">
              Total Outstanding Amount
            </h6>
            <h2 class="font-weight-bolder text-right">
              <vue-numeric
                read-only
                :precision="2"
                currency="MYR"
                separator=","
                :value="settlementOverview.total_outstanding_amount_sum"
              />
            </h2>
          </b-card>
        </b-col>
        <b-col
          lg="4"
          md="6"
          sm12
        >
          <b-card body-class="pb-50">
            <h6 class="mb-3">
              Total Sale Sum
            </h6>
            <h2 class="font-weight-bolder text-right">
              <vue-numeric
                read-only
                :precision="2"
                currency="MYR"
                separator=","
                :value="settlementOverview.total_sale_sum"
              />
            </h2>
          </b-card>
        </b-col>
      </b-row>

    </div>

    <lp-datatable
      ref="settlementDatatableRef"
      :extra-parameter="extraParam"
      title="Settlement"
      store-module="settlement"
      store-data-endpoint="getAdminSettlementRecords"
      store-getter-endpoint="getSettlementRecordList"
      pagination-state-key="settlementList"
      :features="loadedConfig.datatableFeatures"
      :load-system-constant="true"
      default-search-field="merchant_id"
      default-sort-field="created_at"
      :search-options-dropdown="loadedConfig.searchOptsDropdown"
      :conf="loadedConfig"
      @actionBtn-clicked="actionBtnClicked"
      @extraBtn-clicked="extraBtnClicked"
      @status-filter-btn-clicked="statusFilterBtnClicked"
      @payment-provider-btn-clicked="paymentProviderFilterBtnClicked"
    />

    <b-modal
      id="settlementAdminModal"
      ref="settlementAdminModal"
      hide-footer
      header-bg-variant="primary"
      header-text-variant="light"
      header-close-variant="dark"
      title="Settlement"
    >
      <upload-form
        @submitclicked="getUploadData"
      />
    </b-modal>
  </div>
</template>

<script>
import Vue from 'vue'

import Config from '@/views/administration/settlement/formConfig'
import humaniseMixin from '@/common/humanise.mixin'
import LpDatatable from '@/leanpay-components/LPDataTable.vue'
import uploadForm from './Form.vue'

export default {
  components: {
    LpDatatable, uploadForm,
  },
  mixins: [humaniseMixin],
  data() {
    return {
      currentRowData: {},
      cardTitle: 'Settlement',
      dataGridEndpoint: [],
      module: 'settlement',
      confirmCheckbox: false,
      inputSwalData: '',

      extraParam: {
        invoice_status: [
          'PENDING_SETTLEMENT',
        ],
        payment_provider: 'SWITCH_CWALLET',
      },
    }
  },
  computed: {
    loadedConfig() {
      if (Config) {
        return Config
      }
      return {}
    },
    settlementOverview() {
      const list = this.$store.getters['settlement/getSettlementOverview']
      if (list) {
        return list
      } return {
        total_outstanding_amount_sum: 0,
        total_revenue_sum: 0,
        total_sale_sum: 0,
      }
    },
  },
  async mounted() {
    // this.isBusy = false
    const breadcrumbUpdatePayload = [
      { title: 'Settlement' },
    ]
    await this.$store.dispatch('breadcrumbs/setBreadcrumb', breadcrumbUpdatePayload)

    await this.$store.dispatch('settlement/getSettlementOverview')

    // this.initDataGrid()
  },
  methods: {
    actionBtnClicked(e) {
      if (e.actionName === 'disburseSettlement') {
        this.disburseSettlement(e.rowData)
      }
    },
    disburseSettlement(obj) {
      console.log(obj)
      const disburseAmount = obj.total_revenue
      const bankAccountNumber = obj.bank_account_number
      const bankName = obj.bank_name

      const message = `<div class="my-1">
                        <p>Disburse payout with this information?</p>
                        <div class="small d-flex justify-content-between">
                          <div>Amount</div>
                          <h6>
                            MYR ${disburseAmount.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                          </h6>
                        </div>
                        <div class="small d-flex justify-content-between">
                          <div>Bank Name</div>
                          <h6>
                            ${bankName}
                          </h6>
                        </div>
                        <div class="small d-flex justify-content-between">
                          <div>Account Number</div>
                          <h6>
                            ${bankAccountNumber}
                          </h6>
                        </div>
                       </div>`

      const defaultParam = {
        title: 'Confirmation',
        confirmButtonText: 'Confirm',
        showCancelButton: true,
        html: message,
        allowOutsideClick: false,
        allowEscapeKey: false,
        input: 'text',
        inputLabel: 'External Settlement ID',
        inputPlaceholder: 'External Settlement ID',
        customClass: {
          container: 'leanx-swal',
          // header: 'bg-danger',
          confirmButton: 'btn btn-sm btn-primary mr-1',
          cancelButton: 'btn btn-sm btn-secondary',
          input: 'form-control',
        },
        inputValidator: value => {
          if (!value) {
            return 'External Settlement ID is required'
          }
          this.inputSwalData = value
        },
      }
      Vue.swal(defaultParam).then(result => {
        if (result.isConfirmed) {
          const payload = {
            settlement_id: obj.id,
            external_settlement_id: this.inputSwalData,
          }

          this.$store.dispatch('settlement/createSettlementPayoutInvoice', payload).then(() => {
            this.isBusy = false
            this.$refs.settlementDatatableRef.initDataGrid()
          })
        }
      })
    },
    statusFilterBtnClicked(e) {
      if (e === 'completedSettlementFilter') {
        this.extraParam.invoice_status = ['COMPLETED']
        this.$refs.settlementDatatableRef.initDataGrid()
      }
      if (e === 'pendingSettlementFilter') {
        this.extraParam.invoice_status = ['PENDING_SETTLEMENT']
        this.$refs.settlementDatatableRef.initDataGrid()
      }

      console.log('statusFilterBtnClicked', e)
    },
    paymentProviderFilterBtnClicked(e) {
      if (e === 'paymentProviderFilterNone') {
        this.extraParam.payment_provider = null
        this.$refs.settlementDatatableRef.initDataGrid()
      }
      if (e === 'paymentProviderFilterWebpay') {
        this.extraParam.payment_provider = 'SWITCH_WEBPAY'
        this.$refs.settlementDatatableRef.initDataGrid()
      }
      if (e === 'paymentProviderFilterBNPL') {
        this.extraParam.payment_provider = 'SWITCH_BNPL'
        this.$refs.settlementDatatableRef.initDataGrid()
      }
      if (e === 'paymentProviderFilterCC') {
        this.extraParam.payment_provider = 'SWITCH_CC'
        this.$refs.settlementDatatableRef.initDataGrid()
      }
      if (e === 'paymentProviderFilterCrypto') {
        this.extraParam.payment_provider = 'SWITCH_CRYPTOCURRENCY'
        this.$refs.settlementDatatableRef.initDataGrid()
      }
      if (e === 'paymentProviderFilterEwallet') {
        this.extraParam.payment_provider = 'SWITCH_EWALLET'
        this.$refs.settlementDatatableRef.initDataGrid()
      }
      if (e === 'paymentProviderFilterPaypal') {
        this.extraParam.payment_provider = 'SWITCH_PAYPAL'
        this.$refs.settlementDatatableRef.initDataGrid()
      }
      if (e === 'paymentProviderFilterStripe') {
        this.extraParam.payment_provider = 'SWITCH_STRIPE_CC'
        this.$refs.settlementDatatableRef.initDataGrid()
      }
      if (e === 'paymentProviderFilterCCEmbed') {
        this.extraParam.payment_provider = 'SWITCH_STRIPE_CC_EMBED'
        this.$refs.settlementDatatableRef.initDataGrid()
      }

      console.log('paymentProviderFilterBtnClicked', e)
    },
    extraBtnClicked(e) {
      if (e === 'uploadSettlementBtn') {
        this.$refs.settlementAdminModal.show()
      }
      console.log(e)
    },
    buildFormData(formData, data, parentKey) {
      if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File)) {
        Object.keys(data).forEach(key => {
          this.buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key)
        })
      } else {
        const value = data == null ? '' : data

        formData.append(parentKey, value)
        for (const pair of formData.entries()) {
          console.log(`${pair[0]}, ${pair[1]}`)
        }
      }
    },
    jsonToFormData(data) {
      const formData = new FormData()

      this.buildFormData(formData, data)

      return formData
    },
    getUploadData(e) {
      const payload = {
        ...e,
        collection_id: this.$route.params.id,
      }

      const payload2 = {
        collection_id: this.$route.params.id,
        start_date: this.defaultDateRange.startDate,
        end_date: this.defaultDateRange.endDate,
        limit: 10,
        skip: 0,
        search: {
          search_enable: false,
          search_key: '',
          search_column: '',
          search_replace_word_enable: true,
          search_word_replace: ' ',
          search_word_replace_to: '_',
        },
        sort: {
          parameter_name: 'created_at',
          sort_type: this.sortDesc ? 'asc' : 'desc',
        },
      }

      this.$store.dispatch('settlement/uploadAdminSettlement', this.jsonToFormData(payload))
        .then(res => {
          if (res.data.response_code === 2000) {
            this.$store.dispatch('settlement/getAdminSettlementRecords', payload2)

            this.$refs.settlementAdminModal.hide()
          }
        })
    },
  },
}
</script>

<style lang="scss">
.leanx-swal {
  .swal2-input-label {justify-content: start;}
  .swal2-input {margin-bottom: 2rem}
}
</style>
